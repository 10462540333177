import { Image, Modal} from '@storybook';
import { useRecoilState } from 'recoil';
import { LOCATION_MODAL_KEY, LOCATION_STEPS } from './constant';
import { useMemo } from 'react';
import { LocationPermissionState } from './states';

import './location-permission.scss';


export const LocationPermission = () => {
	const [locationPermission, setLocationPermission] = useRecoilState(
		LocationPermissionState
	);
	const domain = useMemo(() => {
		try {
			const host = window?.top?.location.host || '';
			const domainArr = host.split('.');
			if (domainArr.length >= 2) {
				return [
					domainArr[domainArr.length - 2],
					domainArr[domainArr.length - 1],
				].join('.');
			}
			return 'simplici.io';
		} catch (error) {
			return 'simplici.io';
		}
	}, []);
	return (
		<Modal
			className="LocationPermission__modal"
			modalName="location permission"
			isOpen={locationPermission === 'open'}
			closeModal={() => {
				setLocationPermission('closed');
				localStorage.setItem(LOCATION_MODAL_KEY, 'true');
			}}
			overlayStyles={{ overlay: { zIndex: 999999 } }}
		>
			<div className="LocationPermission">
				<Image
					className="LocationPermission__img"
					fileName="location-cloud.svg"
				/>
				<div className="LocationPermission__steps-continer">
					<p className="LocationPermission__heading">
						{`Allow ${domain} to access your location`}
					</p>
					<ul>
						{LOCATION_STEPS.map((step, stepIndex) => {
							const key = stepIndex + '_STEP';
							return (
								<li key={key}>
									{stepIndex + 1}
.
{' '}
{step}
									{stepIndex === 0 ? domain : ''}
									{stepIndex === 3 ? domain : ''}
.
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</Modal>
	);
};
