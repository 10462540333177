import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import {locationInfoState} from 'states/gesture-state';
import { LOCATION_MODAL_KEY, LocationPermissionState } from 'views/location-permission';

/* 
 This hooks help us to get user Location info 
 */
export const useLocation = () => {
	const [locationInfo, setLocationInfo] =
		useState<Partial<GeolocationCoordinates>>();

	const [locationInformation, setLocationInformation] =
		useRecoilState(locationInfoState);
		const [locationPermission, setLocationPermission] = useRecoilState(
			LocationPermissionState
		);
	const fetchLocation = useCallback(
		() =>
			navigator.geolocation.getCurrentPosition(
				(info) => {
					/*
					setting only few values as Other is not required  if coords is available
				  */
					if (info?.coords){
						setLocationInfo({
							accuracy: info.coords.accuracy,
							altitude: info.coords.altitude,
							altitudeAccuracy: info.coords.altitudeAccuracy,
							latitude: info.coords.latitude,
							longitude: info.coords.longitude,
						});
						setLocationPermission('closed');
						localStorage.removeItem(LOCATION_MODAL_KEY);
					}
					
					if (!locationInformation.latitude) {
						setLocationInformation({
							accuracy: info.coords.accuracy,
							altitude: info.coords.altitude,
							altitudeAccuracy: info.coords.altitudeAccuracy,
							latitude: info.coords.latitude,
							longitude: info.coords.longitude,
						});
					}
				},
				(error) => {
					// eslint-disable-next-line no-console
					console.warn('error', error);
					if (
						locationPermission === '' &&
						!localStorage.getItem(LOCATION_MODAL_KEY)
					) {
						setLocationPermission('open');
					}
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
			),
		[locationInformation, setLocationInfo]
	);
	/*
			Calling this method only if locationinfo does not contain anything ,    
		  */
	if (!locationInfo) fetchLocation();

	/* 
    Do not need to return method for that reason we are returing state that we have saved on line 7
    */
	return { locationInfo, fetchLocation };
};
